import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Routes from './Routes/Routes'
import {useDispatch, useSelector} from 'react-redux'
import {reloadAuthDetails} from './actions/authActions'
import AlertDialogBox from './components/AlertDialogBox'
import './App.css'

function App() {
	const dispatch = useDispatch()
	const auth = useSelector((state) => state.auth)

	useEffect(() => {
		if (auth.isAuthenticated !== true && auth.token) {
			dispatch(reloadAuthDetails(auth.token))
		}
	}, [dispatch, auth.isAuthenticated, auth.token])
	//Trying to redeploy
	return (
		<div className='main-area-wrap'>
			<AlertDialogBox />
			<Router>
				<Route component={Routes} />
			</Router>
		</div>
	)
}

export default App
