const API_URL = 'https://api.collegepass.org/advisingApi/v1/';
const API_URL_V2 = 'https://api.collegepass.org/';

// const API_URL_V2 = 'http://localhost:4000/'
// const API_URL = 'http://localhost:4000/advisingApi/v1/'


export const APIGetUserAdvisors = `${API_URL_V2}advising/v2/utils/getadvisors/`

export const APIstoreUserTask = `${API_URL}storeUsersTask`;

export const APIgetUserTask = `${API_URL}getUserTasks/`;

export const APIMarkAsDone = `${API_URL}markTodoCompleted/`;

export const APIUpdateVisibility = `${API_URL}updateVisibility/`;

export const APIRemoveTask = `${API_URL}removeUsersTask/`;

export const APIGetUserConnection = `${API_URL}getUsersConnections/`;

export const APIUpdateUserConnection = `${API_URL}updateConnectionField/`;

export const APIGetUserContext = `${API_URL}fetchAllContext/`;

export const APIStoreUserContext = `${API_URL}storeContext/`;

export const APIUpdateUserContext = `${API_URL}updateUserContext`;

export const APIGetShortlistedColleges = `${API_URL}getCollegeList/`;

export const getStudentGradeDataAPI = `${API_URL}gpa/getStudentGradeData/`;

export const getGPAValue = `${API_URL}gpa/getGPAValue/`;

export const getMeetingDetails = `${API_URL}meeting/getMeetingDetails/`;

export const StoreStudentGPA = `${API_URL}GPA/StoreStudentGPA`;

export const getStudentGradeGPAData = `${API_URL}gpa/getStudentGradeGPAData/`;

export const storeShortlistedAPI = `${API_URL}storeShortlistedColleges/`;

export const getShortlistedColleges = `${API_URL}getShortListedColleges/`;

export const getDiscussionBoardMessages = `${API_URL}getMessages/`;

export const insertDiscussionBoardMessage = `${API_URL}storeMessage/`;

export const loadUserDetailsAPI = `${API_URL}getUserDetailsByTokenAdvising/`;

export const loginAPI = `${API_URL}auth/counsellorLogin/`;

export const studentLoginAPI = `https://api.collegepass.org/api/v1/auth/login`;

export const getSummerColleges = `${API_URL}getUserSummerColleges/`;

export const setSummerShortlist = `${API_URL}shortlistSummerCollege/`;

export const APIGetUserProfileInfo = `${API_URL}getUserProfileData/`;

export const APIStoreUserPersonalInfo = `${API_URL}storeProfilePersonal/`;

export const APIStoreUserContactInfo = `${API_URL}storeContactPersonal/`;

export const APIGetCountry = `https://api.collegepass.org/api/v1/profile/GetCountry`;

export const APIStoreGeoLangProfile = `${API_URL}storeGeoLanguageProfile/`;

export const APIStoreHouseholdProfile = `${API_URL}storeHouseholdProfile/`;

export const APIStoreParentProfile = `${API_URL}storeParentProfile/`;

export const APIGetStateByCountryID = `https://api.collegepass.org/api/v1/profile/GetStateByCountryID/`;

export const APIGetCityByStateID = `https://api.collegepass.org/api/v1/profile/GetCityByStateID/`;

export const APIStoreAddressProfile = `${API_URL}storeAddressPersonal/`;

export const getExtracurricularDetails = `${API_URL}getExtracurricularData/`;

export const extracurricularMetric = `${API_URL}getExtracurricularMetricField/`;

export const storeExtraCurricular = `${API_URL}storeExtracurricular/`;

export const getUserExtraCurricular = `${API_URL}getUserExtracurricular/`;

export const APIGetAcademicDetails = `${API_URL}getUserAcademicData/`;

export const APIGetActualAchievementDetails = `${API_URL}getActualAchievementData/`;

export const APIGetExtracurricularDetails = `${API_URL}getUserExtracurricular/`;

export const APIGetStudentProfileDetails = `${API_URL}getStudentProfileRecommendation/`;

export const APIGetShortlistedSummerColleges = `${API_URL}getShortlistedSummerColleges/`;

export const APIStoreGPATargetTier = `${API_URL}storeTargetTierGPA/`;

export const APIStoreGPANote = `${API_URL}storeNotesGPA/`;

export const APIStoreTargetTier = `${API_URL}storeTargetTier/`;

export const APIStoreNote = `${API_URL}storeNote/`;

export const APIGetUserCollegeEssays = `${API_URL}getCollegeEssays/`;

export const APIGetUserAwards = `${API_URL}getUserAwards/`;

export const APIGetUserStories = `${API_URL}getUserStoryTelling/`;

export const APIGetUserHomeDetails = `${API_URL}getHomeUserDetails/`;

export const APIStoreUserAwards = `${API_URL}storeAwards/`;

export const APIGetUserAwardsForm = `${API_URL}getUserAwards/`;

export const APIDeleteUserAward = `${API_URL}deleteUserAward/`;

export const APIStoreUserStory = `${API_URL}storeStory/`;

export const APIGetUserStoryForm = `${API_URL}getUserStoryTelling/`;

export const APIDeleteUserStory = `${API_URL}deleteUserStory/`;

export const APIUpdateUserMajor = `${API_URL}storeSpecialization/`;

export const APIStoreSchoolSupport = `${API_URL}storeSchoolSupport/`;

export const APIGetSchoolSupport = `${API_URL}getSchoolSupport/`;

export const APIDeleteSchoolSupport = `${API_URL}deleteSchoolSupport/`;

export const APIGetUserDetailsShortlisting = `${API_URL}getUserDetailsForShortlisting/`;

export const APIGetPastMeetings = `${API_URL}getPreviousRecording/`;

export const APIGetRecordingUrl = `${API_URL}getMeetingRecordingURL/`;

export const APIUpdateStudentAction = `${API_URL}updateStudentAction/`;

export const APIUpdateEditorAction = `${API_URL}updateEditorAction/`;

export const APIUpdateEssayRound = `${API_URL}updateEssayRound/`;

export const APIStoreScore = `${API_URL}standard_tests/storeTestScores/`;

export const APIUpdateScore = `${API_URL}standard_tests/updateTestScores/`;

export const APIgetZoomSignature = `https://api.collegepass.org/api/v1/events/getZoomSignature/`;

export const APIGetUserReadingList = `${API_URL}getUserReadingList/`;

export const APIStoreUserReadingList = `${API_URL}storeReadingList/`;

export const APIDeleteUserReadingList = `${API_URL}deleteReadingList/`;

export const APIGetSummerCollegesEssays = `${API_URL}getSummerCollegeEssays/`;

export const APIUpdateSummerStudentAction = `${API_URL}updateSummerStudentAction/`;

export const APIUpdateSummerEditorAction = `${API_URL}updateSummerEditorAction/`;

export const APIUpdateSummerRound = `${API_URL}updateSummerEssayRound/`;

export const APIStoreSummerPlan = `${API_URL}storeSummerPlan/`;

export const APIDeleteSummerPlan = `${API_URL}deleteSummerPlan/`;

export const APIGetSummerPlan = `${API_URL}getSummerPlan/`;

export const getCounsellorStudentsUrl = `${API_URL}meeting/getCounsellorStudents/`;

export const APIGetUserProfilePic = `${API_URL}profile/getUserProfilePicture/`;

export const APIStoreUserProfilePic = `${API_URL}profile/storeProfilePicture/`;

export const getUserDetailsByID = `${API_URL}getUserDetailsByID/`;

export const APIUKCollegeRequiredData = `${API_URL}getUKRequiredData/`;

export const APIGetUKColleges = `${API_URL}getUKCollegeList/`;

export const APIGetSchoolGradeData = `${API_URL}getUKGradeData/`;

export const getPrimePlusUsers = `${API_URL}getPrimePlusUsers`;

export const updatePrimePlusUser = `${API_URL}updatePrimePlusUser`;

export const getCPUsers = `${API_URL}getCPUsers`;

export const APIGetUserShortlistedCollegesUK = `${API_URL}getUKShortListCollege/`;

export const getCPPrimePlusPlans = `${API_URL}getCPPrimePlusPlans`;

export const APIGetDiscussionMemberList = `${API_URL}getDiscussionBoardMember/`;

export const APIStoreCollegeDocId = `${API_URL}updateCollegeDocId/`;

export const APIStoreSummerCollegeDocId = `${API_URL}updateSummerDocId/`;

export const APIGetApplicationUserEssays = `${API_URL}getApplicationCollegeEssays/`;

export const APIUpdateApplicationUserStudentAction = `${API_URL}updateApplicationStudentAction/`;

export const APIUpdateApplicationUserEditorAction = `${API_URL}updateApplicationEditorAction/`;

export const APIUpdateApplicationRound = `${API_URL}updateApplicationEssayRound/`;

export const APIUpdateApplicationDocId = `${API_URL}updateApplicationDocId/`;

export const APIInsertApplicationEssay = `${API_URL}insertUserApplicationEssay/`;

export const APIUpdateUKCollegeCategory = `${API_URL}updateUKCollegeCategory/`;

export const getUserAlerts = `${API_URL}getUserAlerts`;

export const markAlertRead = `${API_URL}markAlertRead/`;

export const storeAlert = `${API_URL}storeAlert/`;

export const getCanadaShortListCollege = `${API_URL}getCanadaShortListCollege/`;

export const getCanadaCollegeList = `${API_URL}getCanadaCollegeList/`;

export const shortlistCanadaCollegeList = `${API_URL}shortlistCanadaCollegeList`;

export const updateCanadaCollegeCategory = `${API_URL}updateCanadaCollegeCategory/`;

export const shortlistUKCollegeList = `${API_URL}shortlistUKCollegeList`;

export const getIntegratedMedCollegeList = `${API_URL_V2}advising/v2/shortlisting/get-integrated-med-us-colleges`;

export const getIntegratedMedShortListCollege = `${API_URL_V2}advising/v2/shortlisting/shortlisted-med-us-colleges/`;

export const shortlistIntegratedMedCollegeList = `${API_URL_V2}advising/v2/shortlisting/integrated-med-us-colleges`;

export const APIGetCanadaSchoolGradeData = `${API_URL}getCanadaGradeData/`;

export const APIStoreTestData = `${API_URL}standard_tests/storeTestScores/`;

export const APIGetMyMeetings = `${API_URL}meeting/getMyMeetings/`;

export const APIGetCounsellorMeeting = `${API_URL}meeting/getCounsellorStudents/`;

export const APIScheduleMeeting = `${API_URL}meeting/scheduleMeeting/`;

export const APIDeleteMeeting = `${API_URL}meeting/deleteScheduledMeeting/`;

export const APIGetStudentTestData = `${API_URL}standard_tests/getStudentTestData/`;

export const APIGetZOOMData = `${API_URL}getCounsellorZoomData/`;

export const APIUpdateShortlistingStatus = `${API_URL}changeShortlistingLockStatus/`;

export const APIGetShortlistingStatus = `${API_URL}getShortlistedStatus/`;

export const APIgetAPACCollegeList = `${API_URL}getAPACCollegeList/`;

export const APIgetResources = `${API_URL}getResources/`;

export const shortlistAPACCollegeList = `${API_URL}shortlistAPACCollegeList`;

export const updateAPACCollegeCategory = `${API_URL}updateAPACCollegeCategory/`;

export const getAPACShortListCollege = `${API_URL}getAPACShortListCollege/`;

export const shortlistResource = `${API_URL}shortlistResource/`;

export const getUserResources = `${API_URL}getUserResources/`;

export const getEuropeCollegeList = `${API_URL}getEuropeCollegeList/`;

export const getEuropeShortListCollege = `${API_URL}getEuropeShortListCollege/`;

export const shortlistEuropeCollegeList = `${API_URL}shortlistEuropeCollegeList`;

export const updateEuropeCollegeCategory = `${API_URL}updateEuropeCollegeCategory/`;

export const getUserPremiumValues = `${API_URL}getUserPremiumValues/`;

export const APIupdatetier = `${API_URL}updatetier/`;

export const APIUpdateSummerPlantier = `${API_URL}updateSummerPlanTier/`;

export const APIgetMembersListMeeting = `${API_URL_V2}advising/v2/meeting/meeting-members`;

export const APIgetResourcesV2 = `${API_URL_V2}advising/v2/resources/getResources/`;
export const APIgetUserResourcesV2 = `${API_URL_V2}advising/v2/resources/getUserResource/`;

export const APIupdateResourcesYearMonthV2 = `${API_URL_V2}advising/v2/resources/updateMonthYear/`;
export const APIupdateSCYearMonthV2 = `${API_URL_V2}advising/v2/resources/update-sc-month-year/`;

export const APIsendWelcomeEmail = `${API_URL_V2}advising/v2/utils/welcome-message/`;

export const APIGetAdmissionsUsers = `${API_URL_V2}advising/v2/manager/get-admissions-users`;

export const APISyncShortlistUsers = `${API_URL_V2}advising/v2/shortlisting/colleges-sync`;

export const APISyncDELShortlistUsers = `${API_URL_V2}advising/v2/shortlisting/colleges-sync`;

export const APISyncGETShortlistUsers = `${API_URL_V2}advising/v2/shortlisting/colleges-sync`;

export const APIEC_Delete = `${API_URL_V2}advising/v2/extracurricular`;

export const GET_AID = `${API_URL_V2}advising/v2/financialaid/getaid`;

export const shortlistAid = `${API_URL_V2}advising/v2/financialaid/shortlistAid/`;

export const APIgetUserAidV2 = `${API_URL_V2}advising/v2/financialaid/getUserAid/`;